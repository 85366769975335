import React from 'react';
import HeroWriting from '../home/heroWriting';
import HeroImage from '../home/heroImage';

const HomePage = () => {
    return (
        <div className="container-fluid HomePage d-flex align-items-center py-5">
            <div className="row row-custom w-100 align-items-center">
                <div className="col-lg-6 col-md-12 text-center text-lg-start mb-4 mb-lg-0">
                    <HeroWriting />
                </div>
                <div className="col-lg-6 col-md-12 text-center text-lg-end">
                    <HeroImage />
                </div>
            </div>
        </div>
    );
};

export default HomePage;
