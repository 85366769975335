import React from 'react';
import Social from './social';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 pt-5">
            <div className="container-fluid">
                <div className="d-flex justify-content-center align-items-center position-relative">
                    
                    <div className="col-sm text-start">
                        <a href="/contact-us" className="text-start text-white">Contact Us</a>
                    </div>

                    <div className="col-sm text-center">
                        <span className="text-white">&copy; 2024 Null Frontier. All rights reserved.</span>
                    </div>

                    <div className="col-sm text-end">
                        <Social />
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
