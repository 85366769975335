import React from 'react';
import HeroImageLogo from '../../assets/images/logo/PlaceHolderImage.png';

const HeroImage = () => {
    return (
        <div className="hero-image">
            <img src={HeroImageLogo} className="img-fluid" alt="HeroImage" />
        </div>
    );
};

export default HeroImage;
