import React from 'react';
import GameCard from '../games/GameCard';
import { useFetchGameCardQuery } from '../../store/apis/gamesApi';


const GameCardPage = () => {

    const { data: gameCard, error, isFetching } = useFetchGameCardQuery();

    console.log("game card", gameCard);

    let content;
    if (isFetching) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = <div>Error: {error.message}</div>;
    } else if (!gameCard || !gameCard.records) {
        content = <div>No games found.</div>;
    } else {

        // Sort the games by the date or a relevant field to ensure the latest game is first
        const sortedGames = [...gameCard.records].sort((a, b) => new Date(b.xata.createdAt) - new Date(a.xata.createdAt));

        const latestGame = sortedGames[0];
        const otherGames = sortedGames.slice(1);

        content = (
            <div >

                {/* Large top card for the newest game */}
                <div className="row mb-5">
                    <div className="col-12">
                        <GameCard
                            id={latestGame.id}
                            title={latestGame.title}
                            description={latestGame.description}
                            image={latestGame.image[0].url}  // Assuming image is now xata_file type
                            link={latestGame.link}
                            isLarge={true}
                            type={latestGame.type}
                            tags={latestGame.tags}
                            games={latestGame.games}
                        />
                    </div>
                </div>
                
                {/* Grid layout for the rest of the games */}
                <div className="row justify-content-center">
                    {otherGames.map((game, index) => (
                        <div className="col-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center" key={game.id}>
                            <GameCard
                                id={game.id}
                                title={game.title}
                                description={game.description}
                                image={game.image[0].url}  // Assuming image is now xata_file type
                                link={game.link}
                                type={game.type}
                                tags={game.tags}
                                games={game.games}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="container game-page">
            {content}
        </div>
    );
};

export default GameCardPage;
