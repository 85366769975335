

import { Routes, Route } from 'react-router-dom';

import HomePage from './components/pages/homePage';
import GameCardPage from './components/pages/gameCardPage';
import GameContentPage from './components/pages/gameContentPage';
import AboutUsPage from './components/pages/aboutusPage';
import BlogPostCardPage from './components/pages/blogPostCardPage';
import BlogPostContentPage from './components/pages/blogPostContentPage';
import ContactUsPage from './components/pages/contactusPage';

import StarsLayer from './components/starsLayer';
import Footer from './components/footer';

import LogoImg from './assets/images/logo/PlaceHolderImage.png';

import './assets/styles/commonStyle.css';
import './assets/styles/homeStyle.css';
import './assets/styles/gameCardStyle.css';
import './assets/styles/gameContentStyle.css';
import './assets/styles/blogCardStyle.css';
import './assets/styles/blogContentStyle.css';
import './assets/styles/aboutusStyle.css';
import './assets/styles/contactusStyle.css';

import { Navbar, Nav } from 'react-bootstrap';


function App() {
    return (
        <div className="d-flex flex-column min-vh-100">

            <StarsLayer />

            <header className="mb-5">
                <Navbar variant="dark" expand="lg">
                    <div className="container-fluid">

                        <Navbar.Brand href="/" className="col-sm">
                            <img className="logo ms-2" src={LogoImg} width="75" height="75" alt="Null Frontier Logo" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse id="basic-navbar-nav" className="col-sm">
                            <Nav className="mx-auto fs-2">
                                <Nav.Link href="/" className="nav-item nav-link me-4">Home</Nav.Link>
                                <Nav.Link href="/game" className="nav-item nav-link me-4">Games</Nav.Link>
                                <Nav.Link href="/blog" className="nav-item nav-link me-4">Blog</Nav.Link>
                                <Nav.Link href="/about-us" className="nav-item nav-link me-4">About us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="col-sm mobileSize">

                        </div>
                    </div>
                </Navbar>
            </header>

            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/game' element={<GameCardPage />} />
                <Route path="/game/:id" element={<GameContentPage />} />
                <Route path='/blog' element={<BlogPostCardPage />} />
                <Route path="/blog/:id" element={<BlogPostContentPage />} />
                <Route path='/about-us' element={<AboutUsPage />} />
                <Route path='/contact-us' element={<ContactUsPage />} />



            </Routes>

            <Footer />

        </div>
    );
}
export default App;

