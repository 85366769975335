import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const BlogPostCard = ({ id, title, date, description, image, blogs, type, tags, author }) => {
    return (
         <Link to={`/blog/${blogs.id}`} style={{ textDecoration: 'none', width: '100%' }}>
            <Card className="blog-post-card d-flex flex-wrap flex-row">

                <div className="blog-post-image">
                    <Card.Img src={image} alt={title} className="blog-post-img" />
                </div>
                
                <div className="blog-post-card-content flex-grow-1">
                    <Card.Body>
                        <Card.Text className="blog-post-date">{date}</Card.Text>
                        <Card.Title className="blog-post-title">{title}</Card.Title>
                        <Card.Text className="blog-post-description">
                            {description}
                        </Card.Text>
                        <Button variant="primary" className="blog-post-button">Read More</Button>
                    </Card.Body>
                </div>
                
            </Card>
        </Link>
    );
};

export default BlogPostCard;
