import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

const GameCard = ({ id, title, description, image, games, isLarge = false }) => {
    return (
        <Link to={`/game/${games.id}`} style={{ textDecoration: 'none', width: '100%' }}>
            <Card className={`game-card text-white text-center ${isLarge ? 'game-card-large' : ''}`}>
                <Card.Img src={image} alt={title} className="game-card-img" />
                <Card.ImgOverlay className="d-flex flex-column justify-content-end">
                    <Card.Title className="game-card-title">{title}</Card.Title>
                    <Card.Text className="game-card-description">
                        {description}
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
        </Link>
    );
};

export default GameCard;
