import React from 'react';

import FacebookImg from '../assets/images/social/facebook-icon.png';
import XImg from '../assets/images/social/twitter-icon.png';
import InstagramImg from '../assets/images/social/instagram-icon.png';
import YoutubeImg from '../assets/images/social/youtube-icon.png';
import DiscordImg from '../assets/images/social/discord-icon.png';
import TwitchImg from '../assets/images/social/twitch-icon.png';

const Social = () => {

    return (
        <div>
            <div className="text-end m-auto">
                <a href="https://www.facebook.com/NullFrontier" target="noopener">
                    <img src={FacebookImg} alt="Facebook" className="social-icon" />
                </a>
                <a href="https://x.com/NullFrontier" target="noopener">
                    <img src={XImg} alt="Twitter" className="social-icon" />
                </a>
                <a href="https://www.instagram.com/nullfrontiergames/" target="noopener">
                    <img src={InstagramImg} alt="Instagram" className="social-icon" />
                </a>
                <a href="https://www.youtube.com/@NullFrontier" target="noopener">
                    <img src={YoutubeImg} alt="YouTube" className="social-icon" />
                </a>
                <a href="https://discord.gg/PaWHSsXkrA" target="noopener">
                    <img src={DiscordImg} alt="Discord" className="social-icon" />
                </a>
                <a href="https://www.twitch.tv/nullfrontier" target="noopener">
                    <img src={TwitchImg} alt="Twitch" className="social-icon" />
                </a>
            </div>
        </div>
    );
};

export default Social;