import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { blogsApi } from './apis/blogsApi';
import { gamesApi } from './apis/gamesApi';

export const store = configureStore({
  reducer: {
    [blogsApi.reducerPath]: blogsApi.reducer, //dette er en mere sikker måde, ungår "typo's"
    [gamesApi.reducerPath]: gamesApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {  //Thunk middelware er default når der benyttes Redux Toolkit configureStore.
    return getDefaultMiddleware().concat(blogsApi.middleware, gamesApi.middleware);
  }
});

setupListeners(store.dispatch);

export { useFetchBlogPostCardQuery, useFetchBlogPostContentQuery } from './apis/blogsApi';
export { useFetchGameCardQuery, useFetchGameContentQuery } from './apis/gamesApi';