import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const gamesApi = createApi({
  reducerPath: 'games',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://nfbe.pages.dev/',
  }),
  endpoints(builder) {
    return {
      fetchGameCard: builder.query({
        query: () => ({
          url: 'get-gameCards',
          params: {
          },
          method: 'GET',
        }),
      }),
      fetchGameContent: builder.query({
        // Fetch a specific game by ID
        query: (id) => ({
          url: `get-games?id=${id}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const { useFetchGameCardQuery, useFetchGameContentQuery } = gamesApi;
export { gamesApi };