import React from 'react';

const HeroWriting = () => {
    return (
        <div className="hero-writing">
            <h1>Let's find the boundary where nothingness meets potential.</h1>
            <p>
                Null Frontier is an indie startup based in Denmark, specializing in the development and publishing of video games. 
                Driven by a passion to explore and redefine the boundaries within various game genres, 
                we position ourselves at the frontier of the unknown, where innovation intersects with creativity.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <a href="/games" className="btn btn-primary fs-5">Check our games</a>
            </div>
        </div>
    );
};

export default HeroWriting;
