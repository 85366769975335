import React, { useEffect, useRef } from 'react';

const StarsLayer = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        let w, h;

        const setCanvasExtents = () => {
            w = window.innerWidth;
            h = window.innerHeight;
            canvas.width = w;
            canvas.height = h;
        };

        setCanvasExtents();
        window.addEventListener('resize', setCanvasExtents);

        function Star() {
            this.x = Math.random() * w;
            this.y = Math.random() * h;
            this.size = Math.random() * 1.5;
            this.speed = Math.random() * 0.1;
        }

        Star.prototype.update = function() {
            this.y -= this.speed;
            if (this.y < 0) {
                this.y = h;
                this.x = Math.random() * w;
            }
        };

        Star.prototype.draw = function() {
            ctx.fillStyle = "#FFF";
            ctx.fillRect(this.x, this.y, this.size, this.size);
        };

        const stars = [];
        for (let i = 0; i < 1000; i++) {
            stars.push(new Star());
        }

        const animate = () => {
            ctx.clearRect(0, 0, w, h);
            stars.forEach(star => {
                star.update();
                star.draw();
            });
            requestAnimationFrame(animate);
        };

        animate();

        // Cleanup to remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', setCanvasExtents);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1 // Make sure it sits behind other content
            }}
        />
    );
};

export default StarsLayer;
