import React from 'react';

import SelfiePlaceholder from '../../assets/images/logo/PlaceHolderImage.png';

const AboutUsPage = () => {
    return (
        <div className="text-center aboutusPage container px-3 py-5">
            <div className="row mx-auto mb-5">
                <div className="col-12">
                    <h1>What is Null Frontier?</h1>
                    <p>
                        Null Frontier is a software company specializing in the development and publishing of video games.
                        With an ambition to explore and redefine boundaries within various game genres, we stand on the edge of the unknown,
                        where innovation meets creativity.
                    </p>
                </div>
            </div>

            <hr className="my-5" />

            <div className="row mx-auto mb-5 text-center aboutusPage-Team">
                <h1 className="col-12 mb-4">The Team</h1>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Oliver Hansen" />
                    <h2>Oliver Hansen</h2>
                    <h3 className="fs-6">Co-Founder & Head Programmer</h3>
                    <p>Experienced software developer with a background in Computer Science.  </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Noah Sørensen" />
                    <h2>Noah Sørensen</h2>
                    <h3 className="fs-6">Co-Founder & CG Artist</h3>
                    <p>Skilled creative CG artist with a passion for art.</p>
                </div>
            </div>

            <div className="row mx-auto mb-5 text-center aboutusPage-Team">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Frederick Dufour" />
                    <h2>Frederick Dufour</h2>
                    <h3 className="fs-6">Co-Founder & CG Artist</h3>
                    <p>Talented technical CG artist with a passion for art, history, and myths from the past;.</p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <img src={SelfiePlaceholder} className="img-fluid rounded-circle" alt="Florent Mustafaj" />
                    <h2>Florent Mustafaj</h2>
                    <h3 className="fs-6">Co-Founder & Future Astrophysicist</h3>
                    <p>Brings precision and depth to our games through advanced mathematics, physics, and analytical work.</p>
                </div>
            </div>

            <hr className="my-5" />

            <div className="row mx-auto aboutusPage-Statement">
                <h1>Statements</h1>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h2 className="fw-bold">Mission</h2>
                    <p>
                        Our mission is to develop video games that challenge existing boundaries within various genres by redefining what is possible and
                        introducing new perspectives. We strive to explore and discover new, uncharted territories within these genres,
                        where we can push the limits of what gaming experiences can be.
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h2 className="fw-bold">Vision</h2>
                    <p>
                        Our vision is to establish ourselves in the gaming industry and thrive within it,
                        all while creating games that reflect our passion and creativity.
                    </p>
                </div>
            </div>

            <div className="row mx-auto aboutusPage-Statement">
                <h2 className="fw-bold  mb-4">Values</h2>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold">Ambitious</h3>
                    <p>
                        
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold">Integrity</h3>
                    <p>

                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold">Innovation</h3>
                    <p>

                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold">Quality</h3>
                    <p>

                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                    <h3 className="fw-bold">Tenacity</h3>
                    <p>
                        
                    </p>
                </div>
            </div>

        </div>
    );
};

export default AboutUsPage;
