import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetchGameContentQuery } from '../../store/apis/gamesApi';
import GameContent from '../games/gameContent';
import { format } from 'date-fns';
import fallbackImage from '../../assets/images/fallback/fallback-image-url.jpg';

const GameContentPage = () => {
    const { id } = useParams();

    const { data: gameContent, error, isFetching } = useFetchGameContentQuery(id);

    console.log("game content", gameContent);

    let content;
    if (isFetching) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = <div>Error: {error.message}</div>;
    } else if (!gameContent || !gameContent.content) {
        content = <div>No game found.</div>;
    } else {
        content = (
            <div>
                <div className="full-game-content">
                    <GameContent
                        title={gameContent.title}
                        titleMedia={gameContent.titleMedia?.[0]?.url || fallbackImage}
                        date={format(new Date(gameContent.xata.createdAt), 'MMMM dd, yyyy')}
                        description={gameContent.description}
                        content={gameContent.content.split('\n\n\n')}
                        media={gameContent.media}
                        type={gameContent.type}
                        tags={gameContent.tags}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="container p-0 blog-page-content">
            {content}
        </div>
    );
};

export default GameContentPage;