import React from 'react';

const ContactUsPage = () => {
    return (
        <div className="flex items-center min-h-screen bg-gray-900 contactus-Page">
            <div className="container mx-auto">
                <div className="max-w-md mx-auto my-10 bg-gray-800 p-5 rounded-md shadow-sm">

                    <div className="text-center contactus-Page-header">
                        <h1 className="my-3 text-3xl font-semibold text-gray-100">
                            Contact Us
                        </h1>
                        <p className="text-gray-400 dark:text-gray-400">
                            Fill up the form below to send us a message.
                        </p>
                    </div>

                    <hr className="my-5" />

                    <div className="m-7 contactus-Page-content">
                        <form action="https://api.web3forms.com/submit" method="POST" id="form">
                            <input type="hidden" name="access_key" value="0640fa6c-c92f-4e02-84ec-5c4396b0e750" />
                            <input type="hidden" name="subject" value="New Submission from Web3Forms" />

                            <div className="mb-6">
                                <label htmlFor="name" className="block mb-2 text-sm text-gray-400">Full Name</label>
                                <input type="text" name="name" id="name" required
                                    className="w-full px-3 py-2 h-12 rounded-sm placeholder-gray-500 text-gray-900 bg-gray-100 text-sm focus:outline-none" />
                            </div>

                            <div className="mb-6">
                                <label htmlFor="email" className="block mb-2 text-sm text-gray-400">Email Address</label>
                                <input type="email" name="email" id="email" required
                                    className="w-full px-3 py-2 h-12 rounded-sm placeholder-gray-500 text-gray-900 bg-gray-100 text-sm focus:outline-none" />
                            </div>

                            <div className="mb-6">
                                <label htmlFor="message" className="block mb-2 text-sm text-gray-400">Your Message</label>

                                <textarea rows="5" name="message" id="message" required className="w-full px-3 py-2 rounded-sm placeholder-gray-500 text-gray-900 bg-gray-100 text-sm focus:outline-none"></textarea>
                            </div>

                            <div className="mb-6">
                                <button type="submit" variant="primary" 
                                    className="w-full bg-indigo-600 inline-block text-white no-underline hover:text-indigo-100 py-4 px-4 rounded-sm focus:outline-none btn btn-primary">
                                    Send Message
                                </button>
                            </div>

                            <p className="text-base text-center text-gray-400" id="result"></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
