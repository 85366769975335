import React from 'react';
import BlogPostContent from '../blog/blogPostContent';
import { useParams } from 'react-router-dom';
import { useFetchBlogPostContentQuery } from '../../store/apis/blogsApi';
import { format } from 'date-fns';
import fallbackImage from '../../assets/images/fallback/fallback-image-url.jpg';

const BlogPostContentPage = () => {
    const { id } = useParams();

    const { data: postContent, error, isFetching } = useFetchBlogPostContentQuery(id);

    console.log("blog content", postContent);

    let content;
    if (isFetching) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = <div>Error: {error.message}</div>;
    } else if (!postContent || !postContent.content) {
        content = <div>No posts found.</div>;
    } else {
        content = (
            <div>
                <div className="full-post-content">
                    <BlogPostContent
                        title={postContent.title}
                        titleMedia={postContent.titleMedia?.[0]?.url || fallbackImage}
                        date={format(new Date(postContent.xata.createdAt), 'MMMM dd, yyyy')}
                        description={postContent.description}
                        content={postContent.content.split('\n\n\n')} 
                        media={postContent.media}  
                        type={postContent.type}
                        tags={postContent.tags}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="container p-0 blog-page-content">
            {content}
        </div>
    );
};

export default BlogPostContentPage;