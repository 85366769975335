import React from 'react';
import Card from 'react-bootstrap/Card';

// Helper function to handle media rendering errors
const renderMedia = (mediaItem, index) => {
    if (!mediaItem) {
        console.error(`Media at index ${index} is undefined or invalid.`);
        return null; // No media to display
    }

    const { url, mediaType } = mediaItem;

    if (!url || !mediaType) {
        console.error(`Media at index ${index} is missing URL or media type.`);
        return null; // Invalid media data
    }

    if (mediaType.includes('image')) {
        // Render image (supports GIFs and standard images)
        return <img key={index} src={url} alt={`Media ${index}`} className="blog-post-content-media img-fluid my-3" />;
    } else if (mediaType.includes('video')) {
        // Render video
        return (
            <video key={index} controls className="blog-post-content-media my-3">
                <source src={url} type={mediaType} />
                Your browser does not support the video tag.
            </video>
        );
    } else {
        console.warn(`Unsupported media type: ${mediaType} at index ${index}`);
        return null; // Unsupported media type
    }
};

const BlogPostContent = ({ title, titleMedia, date, description, media = [], content = [] }) => {
    // Basic validation for required fields
    if (!title) {
        console.error('Title is missing.');
        return <div>Error: Title is missing.</div>;
    }

    if (!content || !Array.isArray(content) || content.length === 0) {
        console.error('Content is missing or invalid.');
        return <div>Error: Content is missing or invalid.</div>;
    }

    return (
        <div className="blog-post-content-container">
            {/* Full-width banner image */}
            <div className="blog-post-content-banner">
                <img src={titleMedia} alt={title} className="blog-post-content-title-media img-fluid" />
            </div>

            <Card className="my-4 blog-post-content text-center">
                <Card.Body>
                    <section className="blog-post-content-header">
                        <Card.Title className="blog-post-content-title">{title}</Card.Title>
                        <Card.Subtitle className="mb-2 blog-post-content-date">{date || 'No date provided'}</Card.Subtitle>
                        <Card.Text className="blog-post-content-description">{description || 'No description provided'}</Card.Text>
                    </section>

                    {/* Main blog content divided into sections */}
                    <section className="blog-post-content-main">
                        <hr className="my-5" />
                        {content.map((section, index) => {
                            const hasMedia = media[index] && media[index].url; // Check if media exists for this section

                            return (
                                <div
                                    key={index}
                                    className={`blog-post-content-section d-flex ${
                                        hasMedia ? (index % 2 === 0 ? 'flex-lg-row' : 'flex-lg-row-reverse') : ''
                                    } align-items-center`}
                                >
                                    {/* Render text section */}
                                    <Card.Text className={`blog-post-content-main-child mt-3 ${hasMedia ? 'flex-grow-1' : 'w-100'}`}>
                                        {section.split('\n').map((line, idx) => (
                                            <span key={idx}>
                                                {line}
                                                <br />
                                            </span>
                                        ))}
                                    </Card.Text>

                                    {/* Render associated media (if available) */}
                                    {hasMedia && (
                                        <div className="blog-post-content-media-container flex-grow-1">
                                            {renderMedia(media[index], index)}
                                        </div>
                                    )}

                                </div>
                            );
                        })}
                    </section>
                </Card.Body>
            </Card>
        </div>
    );
};

export default BlogPostContent;
